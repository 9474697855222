import React from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterTwo from "../Footer/FooterTwo";
import AboutOne from "../HomeOne/AboutOne/AboutOne";
import TextAnimationTwo from "../HomeOne/TextAnimationTwo/TextAnimationTwo";
// import ServicesTwo from "../HomeTwo/ServicesTwo/ServicesTwo";
// import Testimonial from "../HomeOne/Testimonial/Testimonial";
// import PortfoliosOne from "../HomeOne/PortfoliosOne/PortfoliosOne";
import AboutTwo from "../HomeTwo/AboutTwo/AboutTwo";

const About = () => {
  pageTitle("Nosotros");

  return (
    <>
      <HeaderOne></HeaderOne>
      <BreadCrumb></BreadCrumb>
      <AboutOne></AboutOne>
      <TextAnimationTwo></TextAnimationTwo>
      {/* <ServicesTwo></ServicesTwo> */}
      {/* <PortfoliosOne></PortfoliosOne> */}
      <AboutTwo></AboutTwo>

      <FooterTwo></FooterTwo>
    </>
  );
};

export default About;
